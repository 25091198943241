const locationTeams = {
  cluster1: [
    "Medewerkers Bergeijk - Noord",
    "Medewerkers Bergeijk - Zuid",
    "Medewerkers Team Valkenswaard",
    "Medewerkers Team Waalre",
    "Vrije Inschrijving",
  ],
  cluster1zgt: [
    "Medewerkers Team Bergeijk Gewoon Thuis",
    "Medewerkers Team Valkenswaard Gewoon Thuis",
    "Medewerkers Team Waalre Gewoon Thuis",
    "Vrije Inschrijving",
  ],
  cluster2: [
    "Medewerkers Team Meerveldhoven",
    "Medewerkers Team Veldhoven Dorp",
    "Medewerkers Team Veldhoven Oerle",
    "Medewerkers Team Veldhoven Polders",
    "Medewerkers Team Veldhoven Zeelst",
    "Medewerkers Team VTT Regio",
    "Medewerkers Team VTT Stadplus",
    "Medewerkers Team EWT",
    "Vrije Inschrijving",
  ],
  cluster2zgt: [
    "Medewerkers Team Veldhoven Gewoon Thuis",
    "Vrije Inschrijving",
  ],
  cluster3: [
    "Medewerkers Team Cranendonck Noord",
    "Medewerkers Team Cranendonck Zuid",
    "Medewerkers Leende - Sterksel - Engelse Tuin",
    "Medewerkers Team Geldrop Wijkteam 1",
    "Medewerkers Team Geldrop Wijkteam 2",
    "Medewerkers Team Nuenen Noord Gerwen Nederwetten",
    "Medewerkers  Team Nuenen Zuid Oost",
    "Medewerkers Team Nieuwe Hoeven Wiebossen",
    "Vrije Inschrijving",
  ],
  cluster3zgt: [
    "Medewerkers Team Geldrop Gewoon Thuis",
    "Medewerkers Team Nuenen Gewoon Thuis",
    "Medewerkers Team Geldrop Gewoon Thuis,",
    "Medewerkers Team Heeze-Leende Gewoon Thuis",
    "Vrije Inschrijving",
  ],
  cluster4: [
    "Medewerkers Team Airborne Oost",
    "Medewerkers Team Airborne West",
    "Medewerkers team Jagershoef",
    "Medewerkers Team Kronehoef 1",
    "Medewerkers Team Kronehoef 2",
    "Vrije Inschrijving",
  ],
  cluster4zgt: [
    "Medewerkers Team Jagershoef Gewoon Thuis",
    "Vrije Inschrijving",
  ],
  cluster5: [
    "Medewerkers Team Stratum",
    "Medewerkers Team Tongelre",
    "Medewerkers Team Meerhoven-Strijp",
    "Medewerkers MNT",
    "Medewerkers Team Genderbeemd-Gestel",
    "Medewerkers Team Gestel-Centrum",
    "Vrije Inschrijving",
  ],
  cluster5zgt: [
    "Medewerkers Team Eindhoven Zuid Gewoon Thuis",
    "Vrije Inschrijving",
  ],
  cluster6: [
    "Medewerkers Eersel - Dorpen",
    "Medewerkers Team Eersel - Centrum",
    "Medewerkers Team Bladel - Buiten",
    "Medewerkers Team Bladel - Centrum",
    "Medewerkers Team Reusel-de Mierden",
    "Vrije Inschrijving",
  ],
  cluster6zgt: [
    "Medewerkers Team Eersel Gewoon Thuis",
    "Medewerkers Team Bladel Gewoon Thuis",
    "Medewerkers Team Reusel - de Mierden Gewoon Thuis",
    "Vrije Inschrijving",
  ],
  cluster7: [
    "Medewerkers Best - West",
    "Medewerkers Team Best - Oost",
    "Medewerkers Team Son&Breugel",
    "Medewerkers Team Oirschot",
    "Vrije Inschrijving",
  ],
  cluster7zgt: [
    "Medewerkers Team Best Gewoon Thuis",
    "Medewerkers Team Son en Breugel Gewoon Thuis",
    "Medewerkers Team Oirschot Gewoon Thuis",
    "Vrije Inschrijving",
  ],
  cluster8: [
    "Medewerkers TC - Team de Kempen",
    "Medewerkers TC - Team Eindhoven Zuid",
    "Medewerkers TC Team Best - Oirschot - Veldhoven",
    "Medewerkers TC Team Directie en managementonderst.",
    "Medewerkers TC Team Eindhoven Noord",
    "Medewerkers TC Team Thuisondersteuner",
    "Medewerkers TC Team Valkenswaard",
    "Medewerkers TC - team Cranendonck",
    "Vrije Inschrijving",
  ],
  cluster9: [
    "Medewerkers Team Flex",
    "Medewerkers Team ZTB Oost",
    "Medewerkers Team ZTB West",
    "Medewerkers Team Nachtzorg",
    "Team Nachtzorg",
    "Alle Medewerkers Flexpool",
    "Alle medewerkers team ZuidZorgflex - Wijkzorg",
    "Team Zwangerschap",
    "ZZP Losse diensten",
    "ZZP Periodeopdracht",
  ],
  cluster10: [
    "Medewerkers Bedrijfsvoering",
    "Medewerkers H.R.M.",
    "Medewerkers SC-team Financien",
    "Medewerkers stichting",
    "Medewerkers TC Team Directie en managementonderst.",
    "Medewerkers Team Flex",
    "Medewerkers WZ Cluster De Kempen",
    "Medewerkers WZ Cluster Eindhoven Noord",
    "Medewerkers WZ Cluster Eindhoven Zuid",
    "Medewerkers Zorg",
    "Ondersteunend - Bedrijfsvoering",
    "RVB",
    "SC-team Financien",
    "Thuiscoaching",
    "Wijkzorg",
    "WZ Cluster Brabant Zuid",
    "WZ Cluster De Kempen Noord",
    "WZ Cluster de Kempen Zuid",
    "WZ Cluster Eindhoven Noord",
    "WZ Cluster Eindhoven Zuid",
    "WZ Cluster Gewoon Thuis",
    "WZ Cluster De Kempen",
    "Medewerkers WZ Cluster Noord-Oost",
    "Medewerkers WZ Cluster Noord-West",
    "WZ Cluster Zuid-Oost",
    "WZ Cluster Veldhoven",
    "Zorg",
    "Zorginhoudelijk Adviseur VPT",
    "Medewerkers Team Directieondersteuning",
    "Staf",
  ],
};

export default locationTeams;
